import { red } from "@material-ui/core/colors";
import { createMuiTheme, Theme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
});

const themeWithOverrides: Theme = {
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiTypography: {
      h1: {
        fontWeight: "bold",
        margin: "3rem 0",
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
      },
    },
  },
};

export default themeWithOverrides;
