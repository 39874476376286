import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import theme from "../theme";

interface TopLayoutProps {
  children: ReactNode;
}

const TopLayout: React.FunctionComponent<TopLayoutProps> = (
  props: TopLayoutProps
) => {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <>
          <CssBaseline />
          {props.children}
        </>
      </ThemeProvider>
    </>
  );
};

export default TopLayout;
